import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import dynamic from "next/dynamic";
import { useParams } from "next/navigation";
import { Suspense } from "react";

import type { BookingStoryblok } from "@/component-types-sb";
import { Spinner } from "@/components/layout/Spinner";
import type { Language } from "@/lib/types";

const IFrame = dynamic(
  () => import("../../layout/IFrame").then((module) => module.IFrame),
  { ssr: false }
);

type BookingProps = {
  blok: BookingStoryblok;
};

const Booking = ({ blok }: BookingProps) => {
  const { lang } = useParams<{ lang: Language }>();

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <Suspense fallback={<Spinner />}>
        <IFrame className="mb-48" lang={lang} />
      </Suspense>
    </article>
  );
};

export default Booking;
